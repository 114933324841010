.ty-popover{
	background-color: white;
	padding: 7px;
    border-radius: 8px;
    box-shadow: 0px 0px 48px -18px rgba(0,0,0,0.75);
}

.ty-popover .ty-input__input{
	width: 100%;
	margin: 0em 0px 1.2em;
	box-sizing: border-box;
	color: #32325d;
	border: none;
	transition: all 0.3s;
	outline: 0;
	border-radius: 5px;
	font-size: 14px;
	box-shadow: -0.5px -0.5px 1px 1px rgba(50, 50, 71, 0.07), 1px 1px 3px 0 rgba(50, 50, 71, 0.16) !important;
	height: 30px;
}

.ty-popover .ty-btn_danger{
	box-sizing: border-box;
  	border: 1px solid #d0d0d5;
    border-top-color: rgb(208, 208, 213);
    border-right-color: rgb(208, 208, 213);
    border-bottom-color: rgb(208, 208, 213);
    border-left-color: rgb(208, 208, 213);
	outline: none;
	letter-spacing: 0;
	text-align: center;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 50px;
	vertical-align: middle;
	text-decoration: none;
	white-space: nowrap;
	user-select: none;
	border-radius: 6px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	line-height: 1.5;
	font-family: "Inter", sans-serif !important;
	color: #fff;
	background: #f6655a;
	border-color: #f77066;
}

.ty-popover .ty-pagination{
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ty-popover .ty-pagination .ty-pagination__item{
	
	min-width: 24px;
	height: 24px;
	margin-left: 0.5px;
	margin-right: 0.5px;
	line-height: 22px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: #fff;
	border-radius: 2px;
	outline: 0;
	cursor: pointer;
	user-select: none;
	transition: all 400ms;

}

.ty-popover .ty-pagination .ty-pagination__item:nth-child(1){
	transform: rotate(90deg); 
}
.ty-popover .ty-pagination .ty-pagination__item:nth-child(3){
	transform: rotate(270deg); 
}

.ty-popover .chat-layout{
	display: flex;
	flex-direction: column-reverse;
	height: 530px;
	max-height: 580px;
	margin-bottom: 12px;
	overflow-y: auto;
}

.ty-popover .chat-layout .isy-chat-message {
  padding: 6px;
  margin-bottom: 4px;
  border-radius: 6px;
  display: flex;
  gap: 8px;
  position: relative;
  max-width: 100%;
}

.ty-popover .chat-layout .isy-chat-message.me .isy-chat-message-body{
	background: red;
	color: white;
}

.ty-popover .chat-layout .isy-chat-message .isy-chat-message-body{
	word-wrap: normal;
	padding: 8px 12px;
	max-width: 70%;
	background: lightgray;
	border-radius: 6px;
}

.ty-popover .chat-layout .isy-chat-message .isy-chat-message-body .isy-text {
  max-width: 100%;
  display: block;
  word-wrap: break-word;
  white-space: break-spaces;
  font-size: 14px;
}

.ty-popover .chat-layout .isy-chat-message .isy-chat-message-body .isy-chat-time {
  word-wrap: break-word;
  font-style: italic;
  display: block;
  font-size: 10px;
  max-width: 100%;
}

.ty-popover .form-chat {
  display: flex;
  gap: 18px;
  padding: 4px 3px 4px 3px;
}

.ty-popover .form-chat .textarea-message {
  flex: 1;
  box-shadow: unset !important;
  resize: none;
  overflow-y: auto;
  padding: 0;
  font-size: 12px;
  box-sizing: border-box;
width: 100%;
margin: 0;
color: #32325d;
border: none;
transition: all 0.3s;
outline: 0;
border-radius: 5px;
box-shadow: -0.5px -0.5px 1px 1px rgba(50, 50, 71, 0.07), 1px 1px 3px 0 rgba(50, 50, 71, 0.16) !important;
padding: 5px;
overflow: hidden;
  overflow-y: hidden;
}

.ty-popover .form-chat .file-message {
  font-size: 18px;
}

.ty-popover .form-chat .send-message {
  font-size: 18px;
}

.ty-popover .ty-avatar {
	width: 30px;
height: 30px;
font-size: 15px;
line-height: 26px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ccc;
  color: #fff;
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  user-select: none;
  border: 2px solid #fff;
  border-radius: 50%;
}

.ty-popover .ty-avatar__text {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  transform: translateX(-50%);
  transform: scale(0.423077) translateX(-50%);
}
.ty-popover .ty-col-24 {
  width: 100%;
}

.ty-popover .ty-form-item__label {
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ty-popover .ty-form-item {
  margin-bottom: 22px;
}

.ty-popover  .ty-input__input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  color: #32325d;
  border: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  transition: all 0.3s;
  outline: 0;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: -0.5px -0.5px 1px 1px rgba(50, 50, 71, 0.07), 1px 1px 3px 0 rgba(50, 50, 71, 0.16) !important;
}

.ty-popover .ty-textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  color: #32325d;
  border: none;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  transition: all 0.3s;
  outline: 0;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: -0.5px -0.5px 1px 1px rgba(50, 50, 71, 0.07), 1px 1px 3px 0 rgba(50, 50, 71, 0.16) !important;
  padding: 5px;
  overflow: hidden;
}

.ty-popover .ty-select-native {
	font-family: "Inter", sans-serif;
width: 100%;
padding: 8px 12px;
border: none;
box-shadow: -0.5px -0.5px 1px 1px rgba(50, 50, 71, 0.07), 1px 1px 3px 0 rgba(50, 50, 71, 0.16) !important;
  display: inline-flex;
  -webkit-appearance: none;
  cursor: pointer;
  color: #32325d;
  min-width: 200px;
  margin: 0;
  line-height: 18px;
  vertical-align: middle;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij4gIDxwYXRoIGZpbGw9IiMzOTNFNDgiIGQ9Ik0wLjIyMiw1LjYxMyBMNS40NTEsMC4yMjMgQzUuNzU2MjEyMDksLTAuMDc0MzYzMjMzMyA2LjI0Mjc4NzkxLC0wLjA3NDM2MzIzMzMgNi41NDgsMC4yMjMgTDExLjc3OCw1LjYxMyBDMTIuMTgsNi4wMjcgMTIuMDI1LDYuNjY3IDExLjQwNyw2LjY2NyBMMC41OTIsNi42NjcgQy0wLjAyNCw2LjY2NyAtMC4xOCw2LjAyNyAwLjIyMiw1LjYxMyBaIE0wLjU5Miw5LjMzMyBMMTEuNDA3LDkuMzMzIEMxMi4wMjUsOS4zMzMgMTIuMTgsOS45NzMgMTEuNzc3LDEwLjM4NyBMNi41NDcsMTUuNzc3IEM2LjI0MTkxMjU4LDE2LjA3MzcxNDMgNS43NTYwODc0MiwxNi4wNzM3MTQzIDUuNDUxLDE1Ljc3NyBMMC4yMjIsMTAuMzg3IEMtMC4xOCw5Ljk3MyAtMC4wMjQsOS4zMzMgMC41OTIsOS4zMzMgWiIvPjwvc3ZnPg==");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.62em auto, 100%;
  transition: all 0.3s;
  outline: none;
}