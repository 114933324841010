.main {
    min-height: 100vh !important;


    background-image: url('../../../public/bg.jpg') !important;
    background-position-x: center !important;
    background-position-y: top !important;
    background-size: cover !important;
    background-attachment: fixed  !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;
    // // overflow-x: hidden;
}


.content{
    padding: 1em 1em 1em 0.5em;
}

.card-header {
    margin-bottom: 1em !important;
}
.echarts-for-react {
    overflow: hidden;
}

.list-component .ant-spin-container > .ant-row{
    margin: 0 !important;
}
