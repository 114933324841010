
.btn-container{
	width: 200px;
	height: 50px;
	position: absolute;
	background: linear-gradient(91.65deg, #A22E9B 8.89%, #6E21AF 82.25%);
	box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
	border-radius: 50px;
	bottom: 0px;
	visibility: hidden;
	opacity: 0;
	right: 0;
	left: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease-out;
}

.btn-container.show{
	visibility: visible;
  	opacity: 1;
	bottom: 50px;
	transition: all 0.3s ease-out;
	
}

.btn-container .ant-btn,
.btn-container .ant-btn:hover,
.btn-container .ant-btn:focus{
	background: transparent;
	border: none;
}

.ant-popover {
	background: transparent !important;
}

.ant-popover .ant-popover-content {
	background: transparent !important;
	box-shadow: 1px 1px 10px rgba(133, 133, 133, 0.4);
}


.ant-popover .ant-popover-inner {
	background: rgba(255, 255, 255, 0.5) !important;
	border: solid 3px #FFF;
	filter: blur(0.25px);
}


.ant-popover .ant-popover-title{
	font-weight: bolder;
}


.ant-popover .ant-select{
	background: rgba(255, 255, 255, 0.8) !important;
	border: solid 3px #FFF;
	color: #000;
}


.filter-blur{
	background: rgba(255, 255, 255, 0.35) !important;

	width: 100%;
	height: 40px;
	position: initial;
	display: block;
	top: 0;
	filter: blur(0.5px);
}
  

.no-filter-blur{
	margin-top: -40px;
	
}


.change-asesor .ant-popover-content .ant-popover-inner{
    border-radius: 8px;
    border: none; 
    background: none; 
    backdrop-filter: blur(1rem) !important;
    background: rgba(255, 255, 255, 0.3) !important;
}

.btn-full {
	min-height: 35px;
	max-height: 65px!important;
}