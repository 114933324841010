/* CARDS */
.hm-card {
    border-radius: 10px;
    max-width: 300px;
    min-width: 100%;
    width: auto;
    height: 241px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 0px rgb(27 49 66 / 13%) !important;
    border-radius: 10px;
    padding: 1rem;
    position: relative;

    .hm-card-cover .card-avatar {
        width: 100px;
        height: 100px;
        margin: auto;
        display: block;
        border-radius: 50px;
        border: 1px solid transparent;
        box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
    }

    .card-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 1rem;
        line-height: 25px;
        align-items: center;
        text-align: center;

    }

    .card-text-gray {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        color: #787575;
        font-size: 1rem;
        line-height: 25px;
        align-items: center;
        text-align: center;

    }
}