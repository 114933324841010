.sidebar{
    padding: 1em 0.5em 1em 1em;
    max-height: 100vh;
    position: sticky !important;
    top: 0 !important;

    &-card {
        min-height: 100%;
        // width: 100% !important;
        padding: 0 !important;
        display: flex;
        flex: 1;
    
        .ant-card-body{
            min-height: 100%;
            width: 100%;

            padding: 1em 0 !important;

            .sidebar-card-content {
                min-height: 100%;
                display: flex;
                flex-direction: column;

            }

            .sidebar-logo{
                padding: 1em;
            }

            .sidebar-menu{
                &::before,
                &::after {
                    display: none !important;
                }
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-transform: uppercase;
            }
        }
    }
}