/*
 * @component Listas para el despliege de registros generales
 */
 .component-list {
  overflow-x: auto;
}

.component-list .ant-spin-nested-loading > .ant-spin-container > .ant-row{
  margin: 0 !important;
} 

/*
* @component Listas para el despliege de registros generales
* @description Quita el border inferior de los elementos de la lista
*/

.component-list .ant-list-header, .component-list .component-list-item {
  border: none;
}

/*
* @component Listas para el despliege de registros generales
* @description pone la paginacion integradada a la lista del lado izquierdo
*/

.component-list .ant-list-pagination {
  text-align: left;
  margin-bottom: 5rem;
}

/*
* @component Listas para el despliege de registros generales
* @description Quita padding superior del los elementos, para qu eno queden tan separados
*/

.component-list .component-list-item {
  padding-top: 0px;
  padding-bottom: 6px;
}

/*
* @component Listas para el despliege de registros generales
* @description Reduce el padding de los elementos, para que los cards no esten tan grandes
*/

.component-list .component-list-item .ant-card-body {
  padding: 16px !important;
}

/*
* @component Listas para el despliege de registros generales
* @description Creae efecto de hover
*/

.component-list-item .hover:hover {
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}

.hover:hover {
  cursor: pointer;
}

/*
* @component Listas para el despliege de registros generales
* @description Los cards dento de la lista tomen todo el ancho 
*/

.component-list .card-list {
  width: 100%;
}

/*
* @component Listas para el despliege de registros generales
* @description Da mas espacion interior a los cards de las lista
*/

.component-list .card-list .ant-card-body {
  padding: 13px;
  position: relative;
}

/*
* @component Listas para el despliege de registros generales
* @description pequeño linea de color que se encuentra en el lado izquierdo en cada elemento de la lista
*/
.component-list .card-list .badge-card {
  width: 6px;
  height: 60% !important;
  position: absolute;
  left: -2px;
  border-radius: 2px;
}


.component-list .card-list .badge-card.tipo-0 {
  background: #006BFF;
}
/*
* @component Listas para el despliege de registros generales
* @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
*/
.component-list .card-list .badge-card.tipo-1 {
  background: green;
}

/*
* @component Listas para el despliege de registros generales
* @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
*/
.component-list .card-list .badge-card.tipo-2 {
  background: red;
}

/*
* @component Listas para el despliege de registros generales
* @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
*/
.component-list .card-list .badge-card.tipo-3 {
  background: yellow;
}

/*
* @component Listas para el despliege de registros generales
* @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
*/
.component-list .card-list .badge-card.tipo-4 {
  background: gray;
}


/*
* @component Widget de Syncfy, visualización
* @description Permite visualizar el widget de syncfy
*/
.pb-w-sync_modal-container {
  z-index: 10000 !important;
}

.tag-list {
  border-radius: 15px;
  line-height: none !important;
}

.select-header {
  min-width: 250px !important;
}

.select-header .ant-select-selector{
  background: white !important;
}

/*
* Clases globales que se pueden implementar en cualquier parte del sistema
* Son para mover elementos a una cierta posicion o aplicar espacios
*/

.overflow-x{
  min-width: 800px;
  overflow-x: scroll;
}

.d-block {
  display: block !important;
}


.no-border {
  border: none !important;
}

.border{
  border: solid 1px red !important;
}

.border-10{
border-radius: 10px!important;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-center {
  display: block;
  margin: auto;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.flex-column {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-left-column {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.flex-right-column {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-left-column-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.flex-left-column-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.float-left {
  float: left;
}

.heigth-100 {
  height: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.pd-1 {
  padding: 1rem !important;
}

.pd-2 {
  padding: 2rem !important;
}

.pd-3 {
  padding: 3rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pl-1 {
  padding-left: 1.5rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.pb-05 {
  padding-bottom: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.text-center,
.text-center .ant-form-item-label {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-white {
  color: #FFF !important;
}

.text-gray {
  color: #858997 !important;
}

.text-gray-dark {
  color: #222222 !important;
}

.width-100,
.width-100 .ant-upload{
  width: 100%;
}


.width-95 {
  width: 95%;
}

.bg-white {
  background: #FFFFFF;
}

.bg-opacity {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
  border-radius: 5px !important;
  background: rgba(255, 255, 255, 0.25) !important;
}

.disabled-link {
  pointer-events: none;
}
/*
* @component Listas para el despliege de registros generales
* @description Quita padding superior del los elementos, para qu eno queden tan separados
*/

.component-list .component-list-item {
  padding-top: 0px;
}

/*
* @component Listas para el despliege de registros generales
* @description color de la linea de la lista de transacciones, depentiendo del tipo de transaccion
*/
.component-list .ant-list-header,
.component-list .ant-list-items {
  min-width: 1200px;
}

.component-list.medium-list .ant-list-header,
.component-list.medium-list .ant-list-items {
  min-width: 800px;
}

/*
* @component Collapse
* @description Añade un ,argin para separar los elementos
*/
.collapse-list{
  margin-bottom: 0.5rem;
}

/*
* @component Collapse
* @description Estilos para el header del los collapse en general
*/
.collapse-list .ant-collapse-header{
  background-color: #2c77f9;
  border-radius: 6px !important;
}

/*
* @component Collapse
* @description Estilos para el header del los collapse en general
*/
.collapse-list .ant-collapse-content-box{
  background-color: white;
  padding: 16px !important;
  box-shadow: #ccc 1px 1px 15px;
  border-radius: 6px !important;
}

/*
* @component Collapse
* @description El extra del collapse abarca todo el ancho
*/
.collapse-list .ant-collapse-extra{
  width: 100% !important;
}

/*
* @component Collapse
* @description Pone el texto de color blanco
*/
.collapse-list .ant-collapse-extra .ant-typography{
  color: white;
}

/*
* @component Tamaño de la barra de navegación
* @description Es el tamaño universal de la barra de navegación
*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/*
* @component Tamaño de la barra de navegación (area de mobilidad)
* @description Se refiere al area donde el scroll se mueve
*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #BBBBBB;
  border-radius: 20px;
}

/*
* @component La bara de navegación en si, pero el desalizador
* @description CSS del deslizador de la barra de navegación
*/
::-webkit-scrollbar-thumb {
  background: #2d338ca8;
  border-radius: 20px;
}

/*
* @component La barra de navgeación, el thumb al hacer hover
* @description CSS del deslizador pero al pasar el mouse
*/
::-webkit-scrollbar-thumb:hover {
  background: #2d338c;
}


.avatar-uploader .ant-upload {
  margin: 0 auto;
  display: block;
}