.overview {
    &-analytics {
        .ant-card-body {
            display: grid;
            grid-template-columns: 1fr 1px 1fr 1px 1fr;
            align-items: center;
            justify-items: center;
            padding: 25px;
            row-gap: 24px;
            column-gap: 24px;

            &::before,
            &::after {
                display: none !important;
            }
        }

        .analytics {
            display: flex;
            align-items: center;
            column-gap: 30px;

            &-circle {
                width: 84px;
                height: 84px;
                border-radius: 84px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-data {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            &-monto {
                .ant-statistic-content 
                .ant-statistic-content-value 
                .ant-statistic-content-value-int {
                    font-size: 32px;
                    color: #333333;
                }
            }

            &-divider {
                width: 1px;
                height: 85px;
                background-color: #F0F0F0;
            }
        }
    }

}

@media (max-width: 1024px) {
    .overview {
        &-analytics {
            .ant-card-body {
                grid-template-columns: 1fr 1px 1fr;

                &::before,
                &::after {
                    display: none !important;
                }

                &>*:nth-child(4) {
                    grid-column: span 3;
                    width: 100%;
                    height: 1px;
                }

                &>*:nth-child(5) {
                    grid-column: span 3;
                }
            }
        }

    }
}

@media (max-width: 880px) {
    .overview {
        &-analytics {
            .ant-card-body {
                grid-template-columns: 1fr;

                &>*:nth-child(2) {
                    width: 100%;
                    height: 1px;
                }

                &>* {
                    grid-column: span 1 !important;
                }
            }
        }

    }
}