#grapesjs-react {
    max-height: 100% !important;
}

#grapesjs-react-viewer {

    .hms-frame:hover,
    .hms-frame.hovered {
        background-color: transparent !important;
        box-shadow: none !important;
        cursor: default !important;
    }
}

.drawer-editor {
    .ant-drawer-body {
        padding: 0 !important;
    }
}