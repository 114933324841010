#root {
    width: 100%;
    height: 100vh;
}

.form-contact {
    background-color: white;
    position: relative;

    width: 100%;
    height: 100%;

    .ant-input.form-input::placeholder {
        color: white !important;
    }

    .header {
        padding-top: 1em;

        &-logo {
            height: 80px;
            display: block;
            margin: 0 auto;
        }

        &-divider {
            height: 2px;
            width: 80%;
            max-width: 300px;
            // background-color: red;
            margin: 1em auto 0 auto;
        }

        &-description {
            text-align: center;
            width: 100&;
            display: block;
            margin: 1.5em 2.5em 1em 2.5em !important;
            font-size: 15px;
        }
    }

    &-date {
        margin: 1em 2.5em 0 2.5em !important;

        .react-calendar {
            width: 250px;
            max-width: 100%;
            //background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(1, 83, 140, 0.3) 0%, rgba(1, 83, 140, 0.3) 77.08%, rgba(1, 83, 140, 0.3) 100%);
            border: 1px solid rgb(160, 160, 150);
            border-radius: 8px;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
            margin: 2em auto 0em auto;

            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

            color: white;

            &--doubleView {
                width: 700px;

                .react-calendar__viewContainer {
                    display: flex;
                    margin: -0.5em;

                    >* {
                        width: 50%;
                        margin: 0.5em;
                    }
                }
            }

            &,
            & *,
            & *:before,
            & *:after {
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }

            button {
                margin: 0;
                border: 0;
                outline: none;

                &:enabled {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            &__navigation {
                display: flex;
                height: 44px;
                margin-bottom: 1em;

                button {
                    min-width: 44px;
                    background: none;

                    &:disabled {
                        background-color: rgb(240, 240, 240);
                    }

                    &:enabled {

                        &:hover,
                        &:focus {
                            background-color: rgb(230, 230, 230);
                        }
                    }
                }
            }

            &__month-view {
                &__weekdays {
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 0.75em;

                    &__weekday {
                        padding: 0.5em;
                    }
                }

                &__weekNumbers {
                    .react-calendar__tile {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.75em;
                        font-weight: bold;
                    }
                }

                &__days {
                    &__day {
                        &--weekend {
                            color: white;
                        }

                        &--neighboringMonth {
                            // color: rgb(117, 117, 117);
                            color: rgb(237, 237, 237);
                        }
                    }
                }
            }

            &__year-view,
            &__decade-view,
            &__century-view {
                .react-calendar__tile {
                    padding: 2em 0.5em;
                }
            }

            &__tile {
                max-width: 100%;
                padding: 10px 6.6667px;
                background: none;
                text-align: center;
                line-height: 16px;

                &:disabled {
                    // background-color: rgb(240, 240, 240);
                    opacity: 0.4;
                }

                &:enabled {

                    &:hover,
                    &:focus {
                        background-color: rgb(51, 153, 26, 0.7);
                        border-radius: 2px;
                    }
                }

                &--now {
                    $bgcolor: #01538c;
                    // background: $bgcolor;
                    border-radius: 100%;
                    color: white;

                    &:enabled {

                        &:hover,
                        &:focus {
                            //background: lighten($bgcolor, 10%);
                        }
                    }
                }

                &--hasActive {
                    $bgcolor: lighten(rgb(0, 110, 220), 30%);
                    //background: $bgcolor;

                    &:enabled {

                        &:hover,
                        &:focus {
                            //background: lighten($bgcolor, 10%);
                        }
                    }
                }

                &--active {
                    $bgcolor: rgb(0, 110, 220);
                    //background: $bgcolor;
                    color: white;

                    &:enabled {

                        &:hover,
                        &:focus {
                            //background: lighten($bgcolor, 10%);
                        }
                    }
                }
            }

            &--selectRange {
                .react-calendar__tile {
                    &--hover {
                        background-color: rgb(230, 230, 230);
                    }
                }
            }
        }

        .btn-hour-group {
            justify-content: space-between;
            width: 100%;
            min-height: 50px;

            .btn-hour {
                color: #fff !important;
                margin: 0 auto !important;

                //border: 2px solid rgb(1, 83, 140);
                border-radius: 6px !important;
                // background: rgba(1, 83, 140, 0.3);
                // color: rgb(1, 83, 140);


                font-weight: bold;

                &.ant-radio-button-wrapper-checked {
                    opacity: 0.7;
                }

                box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, 0.1) !important;

            }
        }
    }

    .section,
    .width-100 {
        width: 100%;
    }

    .footer {
        text-align: center;
        //position: absolute;
        bottom: 0;
    }

    .btn-select {
        border-radius: 8px;
        display: block;
        color: white;
        //background: linear-gradient(95.44deg, #e8a090 3.81%, #A96958 95.82%);
        margin: 0 auto 1em auto;
    }

    .form-input {
        border-radius: 4px;
        //border: 1px solid #01538C !important;
        //background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(1, 83, 140, 0.3) 0%, rgba(1, 83, 140, 0.3) 77.08%, rgba(1, 83, 140, 0.3) 100%) !important;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

        input {
            color: #01538C !important;
        }

        // textarea.ant-input::placeholder {
        //     color: white !important;
        // }
    }
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .form-contact {
        .header {
            padding-top: 1em;

            &-divider {
                margin: 1em auto 0 auto;
            }

            &-description {
                margin: 1em 1em 1em 1em !important;
            }
        }

        &-date {
            margin: 2em !important;

            .react-calendar {
                margin: 1em auto 0em auto;
            }
        }

        .btn-select {
            margin: 0 auto 1em auto;
        }
    }
}

@media (max-width: 575.98px) {
    .form-contact {
        .header {
            padding-top: 1em;

            &-divider {
                margin: 1em auto 0 auto;
            }

            &-description {
                margin: 1em 1em 1em 1em !important;
            }
        }

        &-date {
            padding: 1em !important;

            .react-calendar {
                margin: 1em auto 0em auto;
            }
        }

        .btn-select {
            margin: 0 auto 1em auto;
        }
    }
}


@media (max-height: 700px) {
    .form-contact {
        .footer {
            text-align: center;
            position: relative !important;

        }
    }
}