$layerIconSize: 15px !default;
$layerNameSpacing: 5px !default;

.#{$nv-prefix} {
  &selected-parent {
    border: 1px solid $colorYell;
  }

  &opac50 {
    @include opacity(0.5);
  }

  &layer {
    font-weight: normal;
    text-align: left;
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: $fontSizeS;
    display: grid;

    &-hidden {
      @include opacity(0.55);
    }

    &-count {
      position: absolute;
      right: 27px;
      top: 9px;
    }

    &-vis {
      left: 0;
      top: 0;
      padding: 7px 5px 7px 10px;
      position: absolute;
      box-sizing: content-box;
      cursor: pointer;
      width: 13px;
      z-index: 1;

      &-off {
        display: none;
      }

      &.#{$nv-prefix}layer-off {
        .#{$nv-prefix}layer-vis-on {
          display: none;
        }
        .#{$nv-prefix}layer-vis-off {
          display: block;
        }
      }
    }

    &-caret {
      width: 15px;
      padding: 2px;
      cursor: pointer;
      position: absolute;
      box-sizing: content-box;
      left: -15px;
      top: 0;
      transform: rotate(90deg);
      @include opacity(0.7);

      &:hover {
        @include opacity(1);
      }

      &.#{$nv-prefix}layer-open {
        transform: rotate(180deg);
      }
    }

    &-title {
      @extend .#{$app-prefix}category-title;

      padding: 3px 10px 5px 30px;
      display: flex;
      align-items: center;

      &-inn {
        align-items: center;
        position: relative;
        display: flex;
        width: 100%;
      }
    }

    &__icon {
      display: block;
      width: 100%;
      max-width: $layerIconSize;
      max-height: $layerIconSize;
      padding-left: 5px;

      svg {
        fill: currentColor;
      }
    }

    &-name {
      padding: $layerNameSpacing 0;
      display: inline-block;
      box-sizing: content-box;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 30px 0 $layerNameSpacing;
      max-width: 170px;
      @extend .#{$app-prefix}no-user-select;

      &--no-edit {
        text-overflow: ellipsis;
      }
    }

    > .#{$nv-prefix}layer-children {
      display: none;
    }

    &.open > .#{$nv-prefix}layer-children {
      display: block;
    }

    &-no-chld > .#{$nv-prefix}layer-title-inn > .#{$nv-prefix}layer-caret {
      display: none;
    }

    &-move {
      padding: 9px 7px;
      position: absolute;
      width: 13px;
      box-sizing: content-box;
      cursor: move;
      right: 0;
      top: 0;
    }

    &.#{$nv-prefix}hovered .#{$nv-prefix}layer-title {
      background-color: rgba(255, 255, 255, 0.015);
    }

    &.#{$nv-prefix}selected .#{$nv-prefix}layer-title {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}


.#{$app-prefix}layers {
  position: relative;
  height: 100%;

  ##{$nv-prefix}placeholder {
    width: 100%;
    position: absolute;

    ##{$nv-prefix}plh-int {
      height: 100%;
      padding: 1px;

      &.#{$nv-prefix}insert {
        background-color: $colorGreen;
      }
    }
  }
}
