.button-arrow {
    width: 100%;
    height: 100%;
    display: block;
    padding-top: 11px;
}


.button-arrow.button-arrow-left {
    box-shadow: 5px 0 8px -6px #888;
    background: rgb(252 225 224) !important;
}

.button-arrow.button-arrow-right {
    box-shadow: -5px 0 8px -6px #888;
    background: rgb(252 225 224) !important;
}





.carousel-prefix {
    position: relative;

    .slick-track {
        display: flex;
    }
}


.carousel-prefix .slick-slider {
    position: relative;
    top: 1px;
    /* width: calc(100% - 20px) !important; */
    display: block !important;
    margin: 0 auto;
}

.carousel-prefix .slick-arrow.slick-prev,
.carousel-prefix .slick-arrow.slick-next {
    display: block !important;
    z-index: 1000;
    visibility: initial !important;
}


.carousel-prefix .avatar-carousel-container {
    display: flex;
    justify-content: center;

    .ant-avatar {
        margin: 0 !important;
        border-radius: 7px;
    }
}

.carousel-prefix .avatar-carousel-container .avatar-carousel {
    display: block !important;
    margin: 0 auto !important;
    border: solid 1px white !important;
}


.carousel-prefix .avatar-carousel-container .avatar-carousel {
    margin-left: 2px;
    margin-left: 2px;
}

.carousel-prefix .avatar-carousel-container {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
}

.title-hm-project {

    color: #fff;
    text-align: center;
    background-color: inherit;
    text-transform: uppercase;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    color: #FCFEFF;
    font-size: 12px;
    letter-spacing: 5px;
}


.selection-carousel {
    padding: 12px 0 6px 0;
    background: linear-gradient(91.65deg, #A22E9B 8.89%, #6E21AF 82.25%);
    border-radius: 6px;
    box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
}


.panes {
    width: 100%;
    justify-content: center;
    gap: 10px;


    .avatar-carousel-container {

        padding: 5px;

        border-radius: 10px 10px  0  0 ;
        .avatar-carousel {
            display: block !important;
            border: solid 1px white !important;
            border-radius: 7px;
            margin: 0 !important;        
        }
    }
}