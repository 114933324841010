.mentions {
  margin: 1em 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
}

.mentions--multiLine .mentions__highlighter {
  padding: 0 0.5rem;
  border: 0;
  font-size: 12pt;
  font-family: "ApercuRegular", Arial, sans-serif;
}

.mentions--multiLine .mentions__input {
  padding: 0 0.5rem;
  outline: 0;
  border: 0;
  font-size: 12pt;
  font-family: "ApercuRegular", Arial, sans-serif;
}

.mentions__suggestions__list {
  background-color: white;
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  color: transparent;
  overflow: hidden;
  height: 50px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  border: 1px solid transparent;
  text-align: start;
}

.emoji__button {
  position: absolute;
  cursor: pointer;
  padding: 1rem 0rem;
  top: 0;
  right: 0;
  background-color: white;
  border: 0;
}

.emoji__picker {
  position: absolute;
  bottom: 10px;
  right: 0;
  float: right;
  margin-left: 200px;
}

.mention-box__input{
  border: 1px solid transparent;
  overflow-y: auto !important;
}

.mention-box__input:focus-visible,
.mention-box__input:focus,
.mention-box__input:active{
  border: 1px solid transparent!important;

}


.mention-box {
  min-height: 55px;
  max-height: 55px;
  border: 1px solid transparent;
  border-radius: 2px !important
}

.mention-box:focus-visible{
  border:1px solid red!important;
}
.mention-box:hover {
  border: 1px solid transparent;
}

.mention-box__highlighter {
  max-height: 40px;
}