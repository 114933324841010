.cnt-page-header .ant-page-header-heading-left
{
    width: 100%;
    display: grid;
} 

.collapse-estatus {

    &-panel {
        margin-bottom: 1em;

        &.ant-collapse-item-active .button-estatus-title {
            border-bottom-right-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
        }


        .ant-collapse-content-box,
        .ant-collapse-header {
            padding: 0 !important;
        }
    }

    &-panel &-arrow{
        transform: rotate(0deg);
        transition: all .5s ease-in-out;
    }

    &-panel.ant-collapse-item-active &-arrow{
        transform: rotate(90deg);
        transition: all .5s ease-in-out;
    }

}

.list-prospectos {
    &-item {
        background: rgba($color: #FFF, $alpha: 0.4);
        border-bottom: 1px solid #ccc !important;


        &:first-child {
            border-top-right-radius: 8px !important;
        }

        &:last-child {
            border-bottom: none !important;
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
        }
    }
}


.empty-board {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    box-shadow: 0px 0px 0px rgb(27 49 66 / 13%) !important;
    width: 100%;
    height: calc(100vh - 100px - 90px);
}

/*
 * @component  div
 * @description Margen del contenedor de los task para poder ver el background y la separacion de Cols
 */
.col-list-task-margin {
    display: inline-block;
    padding: .3rem;
    margin: 8px;
}

/*
   * @component  div
   * @description Si el contenedor esta vacio, se la un tamaño minimo para poder añadir elementos 
   */
div.droppable-container:empty {
    min-height: 109.5px;
}

.title-list-task {
    text-align: center;
    color: #ffffff !important;
    letter-spacing: 0.2px !important;

}

/*
 * @component div
 * @description Color del contenedor de los prospectos
 */

.col-list-task-bg {
    border-radius: 10px;
    background: rgba(116, 115, 115, 0.2);
    border: 1px solid #747373;
    box-shadow: 0px 0px 0px rgb(27 49 66 / 13%) !important;
    max-width: 300px;
    min-width: 215px;
    width: auto;

}


/*styles of tag probabilidad*/
.hm-card-prospecto-tag {
    border-radius: 3px !important;
}

.hm-card-prospecto-tag svg {
    top: 2px;
    position: relative;
}


.hm-card-prospecto * {
    padding: 0;
    margin: 0;
    background: transparent;
}



.hm-card-prospecto {
    border-radius: 10px !important;
    max-width: 250px;
    min-width: 200px;
    width: auto;
    min-height: 100px;
    max-height: 120px;
    padding: 5px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid #FFFFFF;
    margin-bottom: 10px !important;
    margin-left: auto !important;
    margin-right: auto !important;

    .ant-card-body {
        padding: 0px 5px;
    }

    .ant-card-head {
        padding: 0px 5px;
        margin: 0px;
        border: none;
        min-height: 20px !important;
        color: #FFFFFF;

    }

    .ant-card-head .ant-card-head-wrapper  {

        .ant-card-extra{
            padding: 0;
            color: #FFFFFF;
        }
        padding: 0;
        color: #FFFFFF;

    }

    .ant-card-head .ant-card-head-title {
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;

    }

    .hm-card-extra-tiny-text {
        font-size: 8px !important;
    }

    .hm-card-prospecto-text-light {
        font-weight: 200;
        font-size: 10px;
        line-height: 1.5rem;
        vertical-align: top;
        width: 45%;
        color: #ffffff !important;
    }

}



.separator {
    color: #ffffff;
    width: 1px;
    height: 10px;
    margin: 0px 5px;
    border-style: hidden;
    background: #ffffff;
    display: inline-block;
    transform: rotate(25deg);
}

