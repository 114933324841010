.radio-icons {
    border: none;
    float: right;
    background: transparent !important;

    .ant-radio-button-wrapper {
        border: none;
        background: inherit;
    }

    .ant-radio-button-wrapper::before {

        width: 0;
        background-color: transparent;
    }

    .ant-radio-button-checked {
        background: rgba(166, 46, 153, 0.2);
        border-radius: 25%;

    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
    }

    .ant-rate-start {


        div>.ant-rate-star-first {
            color: #000 !important;
        }
    }

}

.inline-form-item {
    width: 100% !important;


    .ant-row {
        flex-direction: row;
        display: flex;
        width: 100% !important;
        justify-content: space-between;
    }

    .ant-form-item-control {
        flex: 4;
    }

    .ant-form-item-label {
        flex: 6;
    }
}

.text-gray {
    color: #696969;
    font-weight: 300;
    font-size: 12px;
}

.custom-rate {

    .ant-rate-star-zero {

        .anticon {
            color: #dadada !important;
        }
    }

}