.whatsapp-preview {
    width: 400px;
    min-height: 500px;
    background: #F1EDE5;

    .ant-card-head {
        padding: 1rem;
        background: rgb(6, 95, 84);
        color: #fff;
    }
}

.template-card-preview {
    width: 300px;
    min-height: 300px;
    height: auto;
    margin: 2rem;

    background: #f7f6f6;
    .ant-card-head {
        padding: .25rem;
        color: gray;
        background: transparent !important;
    }

}

.no-bg {
    background: transparent !important;
}