.hm-calendar{
    .tui-full-calendar-layout,
    .tui-full-calendar-timegrid-timezone,
    
    .toastui-calendar-layout,
    .toastui-calendar-week-view
    {
        background-color: unset !important;
    }

    .hm-card{
        height: auto;
        margin-top: 1.5em;
        backdrop-filter: blur(25) !important;
    }


}

.hm-calendar {


    .toastui-calendar-panel.toastui-calendar-time {
        min-height: 600px;
     
    }

    .toastui-calendar-template-timegridDisplayPrimaryTime,
    .toastui-calendar-day-name__name,
    
    .tui-full-calendar-timegrid-hour,
    .tui-full-calendar-dayname-name
    {
        color: black !important
    }

    .toastui-calendar-timegrid-now-indicator ,
    .tui-full-calendar-dayname-date,
    .tui-full-calendar-timegrid-hourmarker
    {
        display: none !important;
    }

    .toastui-calendar-template-timegridNowIndicatorLabel{
        display: none;
    }

    .toastui-calendar-timegrid-time-column .toastui-calendar-timegrid-time-hidden{
        visibility: visible !important
    }

    .tui-full-calendar-timegrid-hour > span {
        display: block !important;
    }

    .toastui-calendar-day-name__date{
        display: none !important;
    }

    .toastui-calendar-events,
    .toastui-calendar-column{
        background: none !important;
    }
}
