.drawer-prospecto .ant-drawer-content-wrapper .ant-drawer-content{
    overflow: hidden;
}
.drawer-prospecto .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body{
    position: fixed;
}

.hm-card-prospecto-tag {
    border-radius: 3px !important;
}

.hm-card-prospecto-tag svg {
    top: 2px;
    position: relative;
}

.hm-card-prospecto-color-0 {
    background: rgba(255, 0, 0, 0.2) !important;
    border: 2px solid #FF0000 !important;
    color: #FF0000 !important;
}

.hm-card-prospecto-color-1 {
    background: rgba(239, 86, 0, 0.2) !important;
    border: 2px solid #EF5600 !important;
    color: #EF5600 !important;
}

.hm-card-prospecto-color-2 {
    background: rgba(255, 164, 113, 0.2) !important;
    border: 2px solid #FFA471 !important;
    color: #FFA471 !important;
}

.hm-card-prospecto-color-3 {
    background: rgba(255, 189, 152, 0.2) !important;
    border: 2px solid #FFBD98 !important;
    color: #FFBD98 !important;
}

.hm-card-prospecto-color-4 {
    background: rgba(255, 208, 139, 0.2) !important;
    border: 2px solid #FFD08B !important;
    color: #FFD08B !important;
}

.hm-card-prospecto-color-5 {
    background: rgba(228, 232, 30, 0.2) !important;
    border: 2px solid #E4E81E !important;
    color: #E4E81E !important;
    opacity: 0.4;
}

.hm-card-prospecto-color-6 {
    background: rgba(214, 218, 28, 0.2) !important;
    border: 2px solid #D6DA1C !important;
    color: #D6DA1C !important;
}

.hm-card-prospecto-color-7 {
    background: rgba(66, 255, 0, 0.2) !important;
    border: 2px solid #42FF00 !important;
    color: #42FF00 !important;
}

.hm-card-prospecto-color-8 {
    background: rgba(63, 205, 13, 0.2) !important;
    border: 2px solid #3FCD0D !important;
    color: #3FCD0D !important;
}

.hm-card-prospecto-color-9 {
    border-radius: 3px !important;
    background: rgba(59, 176, 17, 0.2) !important;
    border: 2px solid #3BB011 !important;
    color: #3BB011 !important;
}

.hm-card-prospecto-color-10 {
    border-radius: 3px !important;
    background: rgba(48, 135, 17, 0.2) !important;
    border: 2px solid #308711 !important;
    color: #308711 !important;
}



.hm-modal-field-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.2em;
    color: #696969;

}

.hm-modal-field-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 33px;
    display: flex;
    letter-spacing: 0.2em;
}


.hm-tag {
    min-width: 100px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 5px 8px;
    text-align: center;
    line-height: 1rem;
    font-family: Poppins;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.1em;
    opacity: 0.7;
    border: 1px solid #fff;
    font-weight: bolder !important;

    font-size: 0.75rem;


    float: left;
    display: block;
    align-items: center;
    word-break: normal;
}
/* select */
.tag-select {
    width: "100%";
    text-align: "center";
    padding-top: 6;
    padding-bottom: 6;
    font-size: 14;
    font-weight: "bold";
    cursor: "pointer" ;
    border-radius:6px!important;

}

.hm-status-select.ant-form-item-has-success ,
.hm-status-select.ant-form-item-has-success .hm-status-select:hover,
.hm-status-select.ant-form-item-has-success  {
    border: none !important;
}

.hm-status-select>.ant-select-selector {
    padding: 0 !important;
    /* border: 0!important; */
    /* border: 0; */
}



.fixed-bottom{
    position: fixed;
    bottom: 0;
    width: 450px;
}
.fixed-bottom button{
    width: 100%;
}

.footer-drawer{
    color: #696969;
    opacity: 0.5;
    font-style: italic;
    font-weight: 200;
    font-size: 12px;
}