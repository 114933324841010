.tabber {
    display: flex;

    .tab-chat {
        flex: 1;
        height: 100%;
    }

    .ant-tabs-content-holder {
        display: flex !important;

    }

    .ant-tabs-content {}

    .list-items {
        background:
            /* Shadow TOP */
            linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0)) center top,
            /* Shadow BOTTOM */
            linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.08)) center bottom;

        background-repeat: no-repeat;
        background-size: 100% 5px, 100% 5px;
        background-attachment: scroll, scroll;
    }
}